:root {
  --main-color: #00C28e;
  --secondary-color: #fff4e6;
  --main-text-color: #11d7d8;
  --secondary-text-color: pink;
  --send-message-form: white;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="text"] {
  margin: 0px 0px;
  height: 60px;
  color: #1e1e1e;
  font: 400 1rem "proxima-nova", sans-serif;
  width: 100%;
  border: 1px solid #dee0e0;
  padding: 7px 20px;
}

input[type="text"]:focus {
  border: 1px solid #AF9570;
  outline: none !important;
}

input[type="submit"] {
  background-color: #323232;
  color: white;
  font: 700 1rem "proxima-nova",sans-serif;
  border: none;
  position: relative;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  padding: 20px 80px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

input[type="submit"]:hover {
  -webkit-box-shadow: -10px 10px 0px rgba(50,50,50,0.15);
  -moz-box-shadow: -10px 10px 0px rgba(50,50,50,0.15);
  box-shadow: -10px 10px 0px rgba(50,50,50,0.15);
  background-color: #1e1e1e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  overflow: auto;
}

.app {
  display: grid;
  height: 100vh;
  width: 100vw;
  padding: 0 0;
  overflow-y: hidden;
  overflow-x: hidden;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 60px;
  grid-template-areas: 
      "m m m m m m"
      "m m m m m m"
      "m m m m m m"
      "m m m m m m"
      "m m m m m m"
      "m m m m m m";
}

.chat {
  grid-area: m;
  display: grid;
  height: 100%;
  width: 100%;
  padding: 0px 0px;
  overflow-y: hidden;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 60px;
  grid-template-areas: 
      "r m m m m m"
      "r m m m m m"
      "r m m m m m"
      "r m m m m m"
      "r m m m m m"
      "r i i i i i";
}

.chatlist {
  grid-area: r;
  height: 100%;
  width: 100%;
  padding: 0px 0px;
  overflow: hidden;
}

.chatlist {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 0px;
  background: #00c3bf;
  color: var(--secondary-color);
}

.chatlist-entry {
  width: 100%;
  height: 50px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.chatlist-entry-selected .chatlist-entry {
  background: #00000020;
}

.chatlist-entry-name {
  height: 100%;
  width: 75px;
  float: left;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  resize: horizontal;
}

.chatlist-entry-timestamp {
  float: right;
  text-align: right;
  width: 50px; 
  height: 100%;
}

.chatroom {
  grid-area: m;
}

.chatroom {
  box-sizing: border-box;
  padding: 0px 0px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #fff;
}

.message {
  margin: 0px 0px;
}

.message-right {
  margin: 5px 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}
.message-left {
  margin: 5px 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
.message-body {
  margin: 5px 0;
  max-width: 60%;
  background: var(--main-color);
  color: var(--secondary-color);
  justify-content: flex-end;
  padding: 4px 8px;
  border-radius: 8px;
  white-space: pre-wrap;
  float: right;
  clear: both;
}
.message-timestamp {
  justify-content: flex-end;
  padding: 2px 2px;
  border-radius: 0px;
  float: right;
  clear: both;
  font-size: small;
}

.message-input {
  grid-area: i;
  background: var(--send-message-form);
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

h3 {
  text-align: center;
  padding: 0px 0;
  margin: 0;
  border-bottom: 1px solid #ddd;
  background-color: #eee;
}

.chat-input {
  position: relative;
  overflow: hidden;
  padding: 0 0;
  flex-shrink: 0;
}

.chat-input input[type="text"] {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.message-input-form {
  width: 100%;
  padding: 0 0;
  border: none;
  margin: 0;
  background: var(--send-message-form);
  font-weight: 200;
}

.send-message-form input:focus {
  outline-width: 0;
}

.send-message-form input::placeholder {
  color: black;
}
